import React from 'react';
import { Box, Button, Link, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();


  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor: '#f9f9f9',
        borderTop: '1px solid #DDD',
        color: 'text.secondary',
        mt: 'auto',
        py: 3,
        position: 'relative',
        bottom: 0,
      }}
    >
      <Box sx={{ maxWidth: '880px', margin: '0 auto' }}>
        <Grid container alignItems="center" spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={8} display={{ xs: 'block', md: 'flex' }} alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }} textAlign={{ xs: 'center', md: 'left' }}>
            <Box mb={{ xs: 1, md: 0 }}>
              <img src="/static/icon.svg" alt="Logo" style={{ height: 40 }} />
            </Box>
            <Link href="/privacy-policy" underline="hover" color="black" sx={{ ml: { md: 2 }, display: { xs: 'block', md: 'inline' } }}>
              <Typography variant='body2'>Politique de Confidentialité</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'right' }}>
            <Button variant="contained" color="primary" onClick={() => navigate('/contact')}>
              Besoin d'aide ?
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
